.modal-content {
    border-radius: 12px !important;

}

.chat-modal-title {
    font-weight: 600 !important;
}

.chat-modal-header {
    border: 0 none !important;
    box-shadow: 0px 1px 3px 0px #00000026 !important;
}

.chat-modal-header .btn-close {
    font-size: 14px !important;
}

.chat-modal-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 300px !important;
}

.chat-modal-footer {
    border: 0 none !important;
    box-shadow: 0px 1px 8px 0.5px #00000026 !important;
    display: inline !important;
}

.chat-input-section {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.chat-input {
    padding: 8px 15px !important;
    box-shadow: none !important;
    border-radius: 10px !important;
}

.chat-input:focus {
    border: 1px solid #9B1FE8 !important;
}

.chat-input::placeholder {
    color: #919091 !important;
}

.emoji-toggle {
    color: rgb(225, 164, 10) !important;
    margin: 0 10px 0 10px !important;
    cursor: pointer !important;
}

.emoji {
    position: absolute !important;
    bottom: 60px !important;
    right: 2px !important;
}

.send-message {
    color: #8d00e4 !important;
    cursor: pointer !important;
}

.no-message {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
}

.no-message>span {
    color: #919091 !important;
}

.message>div {
    border-radius: 10px !important;
    padding: 6px 10px !important;
    margin-top: 6px !important;
    word-wrap: break-word !important;
    max-width: 350px !important;
}

.message {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    height: 100% !important;
    max-height: 300px !important;
    overflow-y: scroll !important;
}

.message::-webkit-scrollbar {
    display: none !important;
}

.message-sent {
    align-self: flex-end !important;
    background-color: #8d00e4 !important;
    border: 1px solid #8d00e4 !important;
    color: #fff !important;
    word-wrap: break-word !important;
    font-weight: 500 !important;
}

.message-reciever {
    background-color: #e9e8e8 !important;
    font-weight: 500 !important;
}

.notification-message {
    color: #8d00e4 !important;
}

.custom-toast {
    border-radius: 10px !important;
}

@media (max-width: 470px) {
    .message>div {
        max-width: 250px !important;
    }
}

@media (max-width: 345px) {
    .message>div {
        max-width: 230px !important;
    }
}