@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Disable horizontal scrolling */
  overflow-y: auto;   /* Enable vertical scrolling */
}

html, body {
  width: 100%;        /* Ensure full width */
  height: 100%;       /* Ensure full height */
  overflow-x: hidden; /* Prevent horizontal scroll */
  overflow-y: auto;   /* Allow vertical scroll */
}


body {
  background-color: #d0bdd4 !important;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 3.5px;
}

::-webkit-scrollbar-thumb {
  background-color: #464b50;
}

