* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --whiteColor: #fff;
    --blackColor: #000;
    --lightOrangeColor: #FFB84D;
    --darkPinkColor: #DE4B6E;
    --lightPink: #FCE4BF;
    --grayColor: #D4C7C7;
    --buttonFontSize: 0.938rem;
    --inputLabelFontSize: 0.938rem;
    --tableHeaderColor: #F0F0F0;
    overflow: hidden;
    --loaderBgPrimary: rgba(0, 0, 0, 0.5);
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0 !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

input:disabled,
select:disabled,
textarea:disabled {
    background-color: #f3f3f3 !important;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none !important;
}

/*============css for login page===========*/
.login_wrapper {
    width: 100dvw;
    height: 100dvh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_card_wrapper {
    width: 60%;
    background-color: var(--whiteColor);
    padding: 7% 3%;
    border-radius: 1.25rem;
}

.company_name {
    display: grid;
    place-items: center;
    height: 100%;
    border-right: 0.125rem solid var(--blackColor);
}

.company_name h4 {
    text-align: center;
    font-weight: 700;
    font-size: 1.875rem;
    letter-spacing: 0.188rem;
}

.login_form_wrapper {
    width: 70%;
    margin: auto;
    height: 100%;
}

.input_box_wrapper {
    margin-bottom: 0.938rem;
}

.login_input_label {
    width: 100%;
    text-align: center;
    margin-bottom: 0.313rem;
    font-size: 17px;
    font-weight: 500;
}

.input_wrapper {
    width: 100%;
    position: relative;
    border-radius: 1.563rem;
    padding: 0.063rem 0.625rem;
    box-shadow: 0rem 0.188rem 0.188rem 0rem var(--blackColor);
}

.input_icon,
.password_toggler_icon {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(-50%, -50%);
    width: 0.75rem;
}

.password_toggler_icon {
    left: 95%;
    width: 0.95rem;
    color: var(--grayColor);
    cursor: pointer;
}

.login_form_wrapper input {
    padding: 0.313rem 1.25rem 0.313rem 1.563rem;
    border: none;
}

.login_button_wrapper {
    width: 100%;
    margin: 10% 0 0 0;
    text-align: center;
}

.login_button_wrapper button {
    padding: 1% 8%;
    border-radius: 1.563rem;
    color: var(--lightOrangeColor);
    font-weight: 700;
    border: unset;
    background-color: var(--whiteColor);
    box-shadow: 0rem 0.188rem 0.188rem 0rem var(--lightOrangeColor);
}


/*======================css for Loader========================*/

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--loaderBgPrimary);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #4ca6bf;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*======================css for Loader========================*/

.error-message {
    /* height: 4px; */
    margin-top: 3px;
}

.error-message p {
    font-size: 0.6rem;
}